<script>
import { defineComponent, getCurrentInstance, onMounted } from 'vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  setup() {
    onMounted(() => {
      const { proxy } = getCurrentInstance()
      console.log(proxy)
      const { videoId } = useRoute().query
      proxy.$api.AddMediaShareWatchLog({
        mediaBId: videoId,
        model: navigator.appVersion
      }).then((res) => {
        if (res.code == 1) window.location.replace(res.data)
      })
    })
  }
})
</script>

<style>

</style>
